import { Button } from "@/components/ui/button";
import { Link } from "@remix-run/react";
import { motion } from "framer-motion";
import { Briefcase, FileSearch, FileText, Sparkles } from "lucide-react";
import { useEffect, useState } from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

export default function HeroSection() {
    return (
        <div className="min-h-[90vh]">
            <section className="relative overflow-hidden">
                {/* Background Elements */}
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 z-0">
                    {/* Decorative circles */}
                    <div className="absolute top-20 left-10 w-64 h-64 rounded-full bg-pink-500 opacity-20 blur-3xl" />
                    <div className="absolute bottom-10 right-10 w-80 h-80 rounded-full bg-purple-400 opacity-20 blur-3xl" />
                    <div className="absolute top-40 right-20 w-40 h-40 rounded-full bg-blue-300 opacity-20 blur-2xl" />

                    {/* Document pattern overlay */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
                    <div className="absolute inset-0 opacity-5">
                        <div
                            className="h-full w-full"
                            style={{
                                backgroundImage:
                                    "url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
                            }}
                        />
                    </div>
                </div>

                <div className="max-w-[1400px] mx-auto px-6 py-20 md:py-32 relative z-10">
                    <div className="flex flex-col lg:flex-row items-stretch gap-12 lg:gap-24">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: "easeOut" }}
                            className="flex flex-col justify-between text-center lg:text-left lg:flex-1"
                        >
                            <div>
                                <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold tracking-tight max-w-[800px] mx-auto lg:mx-0 leading-[1.1] text-white">
                                    <span className="text-indigo-200">Tailor Your Resume</span> to
                                    Job Descriptions with{" "}
                                    <span className="text-indigo-200">AI</span>
                                </h1>
                                <p className="mt-8 text-lg sm:text-xl text-indigo-100 max-w-2xl mx-auto lg:mx-0 leading-relaxed">
                                    Our AI technology customizes your resume to perfectly match each
                                    job description, creating ATS-friendly applications that
                                    highlight your most relevant skills and experience.
                                </p>
                            </div>
                            <div>
                                <div className="mt-12 flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                                    <Button
                                        size="lg"
                                        className="bg-white hover:bg-indigo-50 text-purple-700 font-bold drop-shadow-lg text-base h-12 px-8 rounded-full"
                                        asChild
                                    >
                                        <Link
                                            to="/auth/signup"
                                            className="inline-flex items-center"
                                        >
                                            Start Tailoring Your Resume
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5 ml-2"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </Link>
                                    </Button>
                                    <Button
                                        size="lg"
                                        variant="outline"
                                        className="border-[1.5px] border-white hover:bg-indigo-50 text-base font-medium h-12 px-8 rounded-full"
                                        asChild
                                    >
                                        <Link to="#how-it-works">See How It Works</Link>
                                    </Button>
                                </div>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 40 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.2, ease: "easeOut" }}
                            className="md:w-1/2 flex justify-center"
                        >
                            <div className="relative w-full max-w-xl">
                                {/* Drop shadow for depth */}
                                <div className="absolute -bottom-4 left-4 right-4 top-4 rounded-lg bg-black/20 blur-md z-0" />
                                {/* Main video with flourish */}
                                <div className="relative z-10 bg-white rounded-lg p-4 shadow-xl transform rotate-1">
                                    <div className="w-full h-full aspect-video">
                                        <LiteYouTubeEmbed
                                            id="p4aMhMnd3ak"
                                            title="Stop Rewriting Resumes: TailoredCV Does It for You! "
                                            adNetwork={false}
                                            noCookie={true}
                                        />
                                    </div>
                                    <div className="absolute -top-3 -right-3 bg-yellow-400 text-yellow-900 font-bold py-1 px-3 rounded-full transform rotate-12 text-sm">
                                        Watch Now!
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>
            <motion.div
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4, ease: "easeOut" }}
                className="w-full max-w-3xl mx-auto mt-24"
            >
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-10">
                    <span className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text text-transparent">
                        Smart Resume Evolution
                    </span>{" "}
                    in Four Simple Steps
                </h2>
                <div className="rounded-2xl bg-white shadow-xl p-8 sm:p-12">
                    <CVTransformation />
                </div>
            </motion.div>
        </div>
    );
}

function CVTransformation() {
    const [currentStep, setCurrentStep] = useState(0);
    const steps = [
        { icon: FileText, label: "Import your basic CV" },
        { icon: FileSearch, label: "Add the job description" },
        { icon: Sparkles, label: "AI enhancement" },
        { icon: Briefcase, label: "Get hired with TailoredCV" },
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
        }, 3000);
        return () => clearInterval(timer);
    }, [steps.length]);

    return (
        <div className="relative">
            <div className="flex justify-between relative z-10">
                {steps.map((step, index) => (
                    <div key={step.label} className="flex flex-col items-center w-16 sm:w-28">
                        <div className="w-8 h-8 sm:w-14 sm:h-14 flex items-center justify-center">
                            <step.icon
                                className={`w-6 h-6 sm:w-10 sm:h-10 transition-colors duration-300 ${
                                    index === currentStep
                                        ? "text-gradient from-pink-600 via-purple-700 to-blue-700"
                                        : "text-gray-300"
                                }`}
                                strokeWidth={1}
                            />
                        </div>
                        <h3
                            className={`mt-1 sm:mt-3 text-[10px] sm:text-sm text-center leading-tight transition-colors duration-300 ${
                                index === currentStep ? "text-gray-900" : "text-gray-400"
                            }`}
                        >
                            {step.label}
                        </h3>
                    </div>
                ))}
            </div>
        </div>
    );
}
