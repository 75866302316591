import { Card, CardContent } from "@/components/ui/card";
import { Brain, FileEdit, FileText, MessageCircle } from "lucide-react";
import { marked } from "marked";
import { useEffect } from "react";

export default function FeaturesSection() {
    useEffect(() => {
        marked.use({
            breaks: true,
            gfm: true,
        });
    }, []);

    const features = [
        {
            icon: FileText,
            title: "Optimize Your CV From Top to Bottom",
            description:
                "Your resume becomes a powerful marketing tool. TailoredCV doesn't just add keywords; it restructures and refines every section—from achievements to soft skills—to create a compelling narrative for hiring managers that passes through ATS systems.",
            link: "",
        },
        {
            icon: Brain,
            title: "Match Your Resume to the Job Description",
            description:
                "Our resume tailoring AI understands what employers want. It analyzes job descriptions and adjusts your resume to make you the ideal candidate, even accounting for industry trends and role-specific requirements to improve your job match score.",
            link: "",
        },
        {
            icon: FileEdit,
            title: "Generate a Personalized Cover Letter",
            description:
                "Never write a cover letter from scratch again. With just a few clicks, TailoredCV generates a custom cover letter that aligns perfectly with your tailored resume, reinforcing your top skills and experience. [Learn more about our AI cover letter generator](https://tailoredcv.ai/ai-cover-letter-generator).",
            link: "https://tailoredcv.ai/ai-cover-letter-generator",
        },
        {
            icon: MessageCircle,
            title: "Ace Your Interview with AI",
            description:
                "Prepare for interviews with confidence! TailoredCV offers personalized preparation tips and practice questions tailored to your job application. Our AI provides insights into common interview questions for your industry, helping you craft compelling answers that showcase your strengths. [Explore our AI interview preparation](https://tailoredcv.ai/ai-interview).",
            link: "https://tailoredcv.ai/ai-interview",
        },
    ];

    return (
        <section className="w-full" id="features">
            <div className="container px-4 md:px-6">
                <div className="flex flex-col items-center justify-center space-y-4 text-center">
                    <div className="space-y-2">
                        <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                            Features That Go{" "}
                            <span className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text text-transparent">
                                Beyond Keyword Optimization
                            </span>
                        </h2>
                        <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                            Discover how our AI-powered platform transforms your job search
                            experience with advanced resume tailoring and CV tailoring features
                            designed for success.
                        </p>
                    </div>
                </div>
                <div className="mx-auto grid max-w-5xl gap-6 py-12 lg:grid-cols-2">
                    {features.map((feature, index) => (
                        <Card
                            key={feature.title}
                            className="group relative overflow-hidden border bg-gradient-to-br from-white to-gray-50/50 transition-all hover:shadow-xl dark:from-gray-900 dark:to-gray-900/50"
                        >
                            <div className="absolute inset-0 bg-gradient-to-br from-pink-600/10 via-purple-700/10 to-blue-700/10 opacity-0 transition-opacity group-hover:opacity-100" />
                            <CardContent className="relative p-6">
                                <div className="flex items-center space-x-4">
                                    <div className="inline-flex size-12 items-center justify-center rounded-lg bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700">
                                        <feature.icon className="size-6 text-white" />
                                    </div>
                                    <h3 className="text-xl font-bold">{feature.title}</h3>
                                </div>
                                <div
                                    className="mt-4 text-muted-foreground prose-sm prose"
                                    // biome-ignore lint/security/noDangerouslySetInnerHtml: marked is a safe library, and the content is created by us
                                    dangerouslySetInnerHTML={{
                                        __html: marked(feature.description),
                                    }}
                                />
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        </section>
    );
}
