import { Card, CardContent } from "@/components/ui/card";
import { FileText, Pencil, Sparkles, Target } from "lucide-react";
import MarketingManagerCVComparison from "./marketing-manager-cv-comparison";

export default function SeeTheDifferenceSection() {
    return (
        <section className="w-full space-y-20">
            {/* Title Section */}
            <div className="container px-4 md:px-6">
                <div className="flex flex-col items-center space-y-4 text-center">
                    <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                        Basic Resume vs. TailoredCV:{" "}
                        <span className="bg-gradient-to-r from-pink-600 via-purple-700 to-blue-700 text-transparent bg-clip-text">
                            See the Difference
                        </span>
                    </h2>
                    <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400">
                        Watch how our AI enhances each part of your resume to make you stand out for
                        every job application by tailoring it to specific job descriptions.
                    </p>
                </div>
            </div>

            {/* Main Content Section */}
            <div className="container px-4 md:px-6">
                <div className="flex flex-col lg:flex-row gap-12">
                    {/* Left Column - Text Content */}
                    <div className="lg:w-1/2 space-y-8">
                        <Card className="relative overflow-hidden">
                            <CardContent className="p-6">
                                <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-pink-600/20 via-purple-700/20 to-blue-700/20 blur-2xl" />
                                <FileText className="w-12 h-12 mb-4 text-pink-600" />
                                <h3 className="text-2xl font-bold mb-2">
                                    Experience Rewritten for Impact
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">
                                    TailoredCV analyzes your job history and highlights relevant
                                    accomplishments. Instead of generic descriptions, it rewrites
                                    each role to showcase the skills and achievements that matter
                                    most to the position you're applying for, making your resume
                                    perfectly tailored to the job posting.
                                </p>
                            </CardContent>
                        </Card>

                        <Card className="relative overflow-hidden">
                            <CardContent className="p-6">
                                <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-pink-600/20 via-purple-700/20 to-blue-700/20 blur-2xl" />
                                <Target className="w-12 h-12 mb-4 text-purple-600" />
                                <h3 className="text-2xl font-bold mb-2">
                                    Skills that Speak to the Role
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">
                                    Our AI doesn't just copy keywords. It intelligently prioritizes
                                    your strongest skills based on the job description, ensuring
                                    they align with what employers are looking for while creating an
                                    ATS-friendly resume that passes through applicant tracking
                                    systems.
                                </p>
                            </CardContent>
                        </Card>

                        <Card className="relative overflow-hidden">
                            <CardContent className="p-6">
                                <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-pink-600/20 via-purple-700/20 to-blue-700/20 blur-2xl" />
                                <Sparkles className="w-12 h-12 mb-4 text-blue-600" />
                                <h3 className="text-2xl font-bold mb-2">
                                    Personalized to Stand Out
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">
                                    From top to bottom, TailoredCV refines every section of your
                                    resume, not just adding keywords but also improving flow and
                                    readability. Our resume builder helps you create a document that
                                    resonates with hiring managers while matching job requirements.
                                </p>
                            </CardContent>
                        </Card>

                        <Card className="relative overflow-hidden">
                            <CardContent className="p-6">
                                <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-pink-600/20 via-purple-700/20 to-blue-700/20 blur-2xl" />
                                <Pencil className="w-12 h-12 mb-4 text-pink-600" />
                                <h3 className="text-2xl font-bold mb-2">
                                    Cover Letters Tailored Instantly
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">
                                    TailoredCV even crafts a customized cover letter that pairs
                                    perfectly with your optimized resume, making it easier than ever
                                    to submit complete, tailored job applications that highlight
                                    your relevant skills and experience.
                                </p>
                            </CardContent>
                        </Card>
                    </div>

                    {/* Right Column - CV Comparison */}
                    <div className="lg:w-1/2">
                        <div className="sticky top-8">
                            <MarketingManagerCVComparison />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
